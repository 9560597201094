import axios from 'axios'
import environment from '@/environments/environment'

const path = environment.apiUrl

export default {
  getDataSalud(params) {
    return axios.post(`${path}/conacyt`, params, {})
  },
}
