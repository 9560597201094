<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ name }}</span>
      <v-spacer></v-spacer>
      <span class="text-xs text--disabled cursor-pointer">Actualizado: {{ fechaActual }}</span>
    </v-card-title>

    <v-card-text>
      <v-row v-if="loading">
        <v-col cols="12">
          <v-sheet
            :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
            class="pa-3"
          >
            <v-progress-linear :indeterminate="true"></v-progress-linear>
            <v-skeleton-loader
              class="mx-auto"
              type="card-avatar"
            ></v-skeleton-loader>
          </v-sheet>
        </v-col>
      </v-row>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </div>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="10"
          md="2"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation(data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation(data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3
              v-if="
                data.title == 'Hombres' ||
                  data.title == 'Mujeres' ||
                  data.title == 'Hospitalizados' ||
                  data.title == 'Ambulatorios'
              "
              class="text-xl font-weight-semibold"
            >
              {{ data.total }}%
            </h3>
            <h3
              v-else
              class="text-xl font-weight-semibold"
            >
              {{ data.total | numberFormat }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHumanQueue,
  mdiAccountOutline,
  mdiHumanFemale,
  mdiHumanMale,
  mdiHospitalBuilding,
  mdiEmoticonSick,
  mdiEmoticon,
  mdiAccountGroup,
  mdiAlarmLight,
  mdiSkullCrossbones,
} from '@mdi/js'

export default {
  data: () => ({
    loading: true,
    statisticsData: [],
    error: false,
    errMessage: '',
    fechaActual: '',
    name: '',
  }),
  mounted() {
    this.getDate()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    getDataCon(data, name) {
      this.statisticsData = data
      this.name = name
      this.loading = false
    },
    getDate() {
      const fecha = new Date()
      let mes = fecha.getMonth() + 1
      let dia = fecha.getDate()
      const anio = fecha.getFullYear()
      if (dia < 10) dia = `0${dia}`
      if (mes < 10) mes = `0${mes}`
      this.fechaActual = `${dia}-${mes}-${anio}`
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
  setup() {
    const resolveStatisticsIconVariation = data => {
      if (data === 'Confirmados') return { icon: mdiHumanQueue, color: 'primary' }
      if (data === 'Negativos') return { icon: mdiAccountOutline, color: 'error' }
      if (data === 'Sospechosos') return { icon: mdiAlarmLight, color: 'warning' }
      if (data === 'Defunciones') return { icon: mdiSkullCrossbones, color: 'error' }
      if (data === 'Recuperados') return { icon: mdiEmoticon, color: 'info' }
      if (data === 'Activos') return { icon: mdiAccountGroup, color: 'primary' }
      if (data === 'Hombres') return { icon: mdiHumanMale, color: 'info' }
      if (data === 'Mujeres') return { icon: mdiHumanFemale, color: 'success' }
      if (data === 'Hospitalizados') return { icon: mdiHospitalBuilding, color: 'warning' }
      if (data === 'Ambulatorios') return { icon: mdiEmoticonSick, color: 'success' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiHumanQueue,
        mdiAccountOutline,
        mdiHumanFemale,
        mdiHumanMale,
        mdiHospitalBuilding,
        mdiEmoticonSick,
        mdiEmoticon,
        mdiAccountGroup,
        mdiAlarmLight,
        mdiSkullCrossbones,
      },
    }
  },
}
</script>
