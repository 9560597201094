<template>
  <v-card>
    <v-card-text>
      <v-sheet
        v-if="loading"
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="pa-3"
      >
        <v-progress-linear :indeterminate="true"></v-progress-linear>
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar"
        ></v-skeleton-loader>
      </v-sheet>
      <div>
        <v-alert
          v-if="error"
          outlined
          type="error"
          transition="scale-transition"
        >
          {{ error }}
        </v-alert>
      </div>
      <div
        v-show="!loading && !error"
        ref="charttequila"
        style="min-height: 400px"
      ></div>
    </v-card-text>
  </v-card>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'

export default {
  data: () => ({
    dayChartMain: null,
    data: null,
    error: false,
    errMessage: '',
    serieM: false,
    serieH: false,
    loading: true,
  }),
  mounted() {
    this.createChart()
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  methods: {
    createChart() {
      this.dayChartMain = am4core.create(this.$refs.charttequila, am4charts.XYChart)

      this.dayChartMain.maskBullets = false
      this.dayChartMain.zoomOutButton.disabled = true

      const label = this.dayChartMain.chartContainer.createChild(am4core.Label)
      label.text = 'Fuente de información: CONACYT - CentroGeo - GeoInt - DataLab'
      label.fontWeight = 200
      label.fontSize = 9
      label.align = 'center'

      // Create axes
      const dayChartMaincDA = this.dayChartMain.xAxes.push(new am4charts.DateAxis())
      dayChartMaincDA.renderer.grid.template.location = 0
      dayChartMaincDA.renderer.minGridDistance = 30
      dayChartMaincDA.renderer.grid.template.disabled = true
      dayChartMaincDA.renderer.fullWidthTooltip = true
      dayChartMaincDA.renderer.labels.template.fontSize = this.iMobileInfoOficial ? 8 : 10

      const dayChartMainvA = this.dayChartMain.yAxes.push(new am4charts.ValueAxis())
      dayChartMainvA.title.text = 'Casos diarios'
      dayChartMainvA.renderer.labels.template.fontSize = this.iMobileInfoOficial ? 8 : 10
      dayChartMainvA.title.fontSize = this.iMobileInfoOficial ? 10 : 12
      dayChartMainvA.cursorTooltipEnabled = false

      const dayChartMaincsAInfoOficial = this.dayChartMain.yAxes.push(new am4charts.ValueAxis())
      dayChartMaincsAInfoOficial.title.text = 'Casos Acumulados'
      dayChartMaincsAInfoOficial.renderer.opposite = true
      dayChartMaincsAInfoOficial.renderer.labels.template.fontSize = this.iMobileInfoOficial ? 8 : 10
      dayChartMaincsAInfoOficial.title.fontSize = this.iMobileInfoOficial ? 10 : 12
      dayChartMaincsAInfoOficial.cursorTooltipEnabled = false
      dayChartMaincsAInfoOficial.renderer.grid.template.strokeOpacity = 0

      dayChartMainvA.extraMax = this.iMobileInfoOficial ? 0.05 : 0.1
      dayChartMainvA.renderer.maxLabelPosition = 0.85

      const accSeries = this.dayChartMain.series.push(new am4charts.LineSeries())
      accSeries.dataFields.valueY = 'acumulado'
      accSeries.dataFields.dateX = 'date'
      accSeries.yAxis = dayChartMaincsAInfoOficial
      accSeries.tooltipText = '{valueY} Acumulado'
      accSeries.name = 'Total Acumulado'

      accSeries.strokeWidth = 2
      accSeries.tooltipText = 'Acumulado: {acumulado}'
      accSeries.showOnInit = true

      const accBullet = accSeries.bullets.push(new am4charts.Bullet())
      accBullet.horizontalCenter = 'middle'
      accBullet.verticalCenter = 'middle'
      accBullet.width = 5
      accBullet.height = 5

      const accRect = accBullet.createChild(am4core.Rectangle)
      accRect.width = 5
      accRect.height = 5

      this.dayChartMain.scrollbarX = new am4core.Scrollbar()
      this.dayChartMain.scrollbarX.parent = this.dayChartMain.bottomAxesContainer

      // Add legend
      this.dayChartMain.legend = new am4charts.Legend()
      this.dayChartMain.legend.fontSize = this.iMobileInfoOficial ? 9 : 13

      // Add cursor
      this.dayChartMain.cursor = new am4charts.XYCursor()
      this.dayChartMain.cursor.fullWidthLineX = true
      this.dayChartMain.cursor.xAxis = dayChartMaincDA
      this.dayChartMain.cursor.lineX.strokeOpacity = 0
      this.dayChartMain.cursor.lineX.fill = am4core.color('#000')
      this.dayChartMain.cursor.lineX.fillOpacity = 0.1
      this.dayChartMain.cursor.behavior = 'none'

      this.dayChartMain.seriesContainer.draggable = false
      this.dayChartMain.seriesContainer.resizable = false
    },
    setData(data) {
      this.dayChartMain.data = data
      this.createStackedSeriesInfoOficial(
        'Femenino',
        'Mujeres',
        this.dayChartMain,
        true,
        this.shadeColorInfoOficial('#d11314', -40),
      )
      this.createStackedSeriesInfoOficial(
        'Masculino',
        'Hombres',
        this.dayChartMain,
        true,
        this.shadeColorInfoOficial('#d11314', 20),
      )
      this.loading = false
    },
    shadeColorInfoOficial(col, amt) {
      let color = col
      let Samt = amt
      let usePound = false
      if (color[0] === '#') {
        color = color.slice(1)
        usePound = true
      }

      let R = parseInt(color.substring(0, 2), 16)
      let G = parseInt(color.substring(2, 4), 16)
      let B = parseInt(color.substring(4, 6), 16)

      if (color === '#000000' && Samt > 0) Samt = 90
      if (color === '#000000' && Samt < 0) Samt = 40

      // to make the colour less bright than the input
      // change the following three "+" symbols to "-"
      R += Samt
      G += Samt
      B += Samt

      if (R > 255) R = 255
      else if (R < 0) R = 0

      if (G > 255) G = 255
      else if (G < 0) G = 0

      if (B > 255) B = 255
      else if (B < 0) B = 0

      const RR = R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16)
      const GG = G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16)
      const BB = B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16)
      if (Samt === 20) {
        this.serieH = true
      } else {
        this.serieM = true
      }

      return (usePound ? '#' : '') + RR + GG + BB
    },
    createStackedSeriesInfoOficial(field, name, chart, stacked, color) {
      console.log(color)
      let col = color
      const series = chart.series.push(new am4charts.ColumnSeries())

      // SAFARI
      if (color === undefined) col = ''

      // Set up series

      series.name = name
      series.dataFields.valueY = field
      series.dataFields.dateX = 'date'
      series.columns.template.fillOpacity = 1.0
      series.columns.template.strokeWidth = 0

      if (col !== '') {
        series.columns.template.fill = am4core.color(col)
        series.fill = am4core.color(col)
        series.stroke = am4core.color(col)
        series.tooltip.getFillFromObject = false
        series.tooltip.background.fill = am4core.color(col)
      }

      // Make it stacked
      series.stacked = stacked
      series.clustered = stacked

      // Configure columns
      series.columns.template.fillOpacity = 0.8
      series.columns.template.width = am4core.percent(95)
      series.columns.template.tooltipText = '[bold]{name}[/]: {valueY}'

      return series
    },
    setError(error) {
      this.loading = false
      this.error = error
    },
  },
  setup() { },
}
</script>
